// src/reducers/searchSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiEndpoint } from "../../config/apiEndpoint";
import { http } from "../../config/http";
import { getLocalStorage } from '../../utils/utils';

// Define the initial state
const initialState = {
  data: [],
  loading: false,
  error: null,
};

interface ILogin {
  page: number,
  search: any
}
// Create an asynchronous thunk to call the search API
export const fetchSearchResults:any = createAsyncThunk(
  'search/fetchSearchResults',
  async (query:ILogin) => {
    try {
      
      const response = await http.post(`${process.env.REACT_APP_BASE_URL}${apiEndpoint.authentication.search}`,{  
          page: 1,
          search: query
      },{
        headers:{
          Authorization : `Bearer ${getLocalStorage('accessToken') || getLocalStorage('GUEST_LOGIN')}`
        }
      });
      return response.data;
    } catch (error:any) {
      throw error.response.data;
    }
  }
);

// Create the search slice
const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchResults.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSearchResults.rejected, (state:any, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default searchSlice.reducer;
