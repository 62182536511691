import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import Header from "../../Components/Header";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../feature/Authentications/LoginPage-Slice";
import { loginMobile } from "../../feature/Authentications/LoginMobile-Slice";
import { sendSMS } from "../../feature/Authentications/SendCode-Slice";
import { useNavigate } from "react-router";
// @ts-ignore
import * as AppleSignin from "react-apple-signin-auth";
import axios from "axios";
import MyAppleSigninButton from "./AppleLogin";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import BeforeLoginHeader from "../../Components/Header2";
import {
  getLocalStorage,
  setLocalStorage,
  showToastErrorMessage,
  showToastSuccessMessage,
} from "../../utils/utils";
import bgImage from "../../login_bg.jpg";
import { googlelogin } from "../../feature/Authentications/GoogleLogin-Slice";
import { applelogin } from "../../feature/Authentications/AppleLogin-Slic";
import { FcGoogle } from "react-icons/fc";
import { ClipLoader } from "react-spinners";

interface IFormInputs {
  email: string;
  password: string;
  mobile?: number;
  sms?: number;
}
const LoginPage = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<IFormInputs>();
  console.log("errors", errors);
  const override: any = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  };
  
  const [showPassword, setShowPassword] = useState("password");
  const [email, setEmail] = useState("");
  const [mobile, setMobile]: any = useState();
  const [loading, setLoading] = useState(false);
  const [resendOtpDisabled, setResendOtpDisabled] = useState(false);
  const [timer, setTimer] = useState(15);
  const [showMailPass, setShowMailPass] = useState("Email");
  const [showOtpField, setShowOtpField] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (e: any) => {
    setShowOtpField(!showOtpField);

    dispatch(login({ e })).then((data: any) => {
      // console.log("DATA", data);
      if (data?.meta.requestStatus === "rejected") {
        navigate("/");
      }

      if (data?.type) {
        navigate("/home");
      }
    });
  };

  const onMobileSubmit = (e: any) => {
    setShowOtpField(!showOtpField);
    setLoading(true);
    dispatch(loginMobile({ e })).then((val: any) => {      
      if (val?.meta?.requestStatus == "rejected") {
        setLoading(false);
        showToastErrorMessage("Invalid verification code")
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }

      if (val?.payload?.data.valid == true) {
        navigate("/home");
      }
      else{
        setLoading(false);
        showToastErrorMessage("Invalid verification code")
      }
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const handleEmail = (val: any) => {
    setShowMailPass(val);
  };

  useEffect(() => {
    let intervalId: any;

    if (resendOtpDisabled) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [resendOtpDisabled]);

  const sendCode = () => {
    dispatch(sendSMS({ mobile })).then((val: any) => {
      if (val?.meta.requestStatus == "rejected") {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    });
    setShowOtpField(true);
  };

  const userLogin = getLocalStorage("accessToken");

  useEffect(() => {
    if (userLogin) {
      navigate("/home");
    }
  }, [userLogin]);

  const Googlelogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      const tokens = await axios
        .post(`${process.env.REACT_APP_BASE_URL}${"/auth/googleWeb"}`, {
          code,
        })
        .then((val: any) => {
          if (val?.data?.id_token) {
            dispatch(googlelogin({ token: val?.data?.id_token })).then(
              (val: any) => {
                if (val?.meta?.requestStatus == "fulfilled") {
                    setLocalStorage("firstName", val?.payload?.data?.user?.firstName);
                    setLocalStorage("lastName", val?.payload?.data?.user?.lastName);
                    setLocalStorage("email", val?.payload?.data?.user?.email);
                  navigate("/home");
                }
              }
            );
          } else {
            return false;
          }
        });
    },
    flow: "auth-code",
  });

  const appleLogin = () => {
    dispatch(applelogin({ token: getLocalStorage("APPLE_LOGIN") })).then(
      (val: any) => {
        console.log("AAPPLE", val);

        if (val?.meta?.requestStatus == "fulfilled") {
            setLocalStorage("firstName", val?.payload?.data?.user?.firstName);
            setLocalStorage("lastName", val?.payload?.data?.user?.lastName);
            setLocalStorage("email", val?.payload?.data?.user?.email);
          navigate("/home");
        }
      }
    );
  };

  return (
    <>
      <div className="bg-gray-100 ">
        <BeforeLoginHeader />
        <div
          className="min-h-screen flex items-center justify-center"
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
        >
          <ClipLoader
              // color={color}
              loading={loading}
              cssOverride={override}
              size={110}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          <div className="max-w-md w-full mx-auto p-8 bg-white rounded shadow-xl">
            <div className="flex flex-col w-full mb-4">
              <div className="text-center leading-6 pt-2 font-bold">Login</div>
            </div>

            <div className="flex gap-x-[-5px] border rounded-2xl ">
              <button
                className={`text-black  px-4 py-2 w-full rounded-2xl ${
                  showMailPass === "Email" && "bg-green text-white"
                }`}
                onClick={() => handleEmail("Email")}
              >
                Email
              </button>
              <button
                className={`text-black  px-4 py-2 w-full   ml-[-30px] ${
                  showMailPass === "Mobile" && "bg-green text-white"
                }  rounded-2xl`}
                onClick={() => handleEmail("Mobile")}
              >
                Mobile
              </button>
            </div>

            {showMailPass == "Email" && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="max-w-sm mx-auto mt-10"
              >
                <>
                  <div className="mb-4">
                    <label htmlFor="email" className="block mb-1 text-sm">
                      Username or Email
                      <span className="pl-[4px] text-red">*</span>
                    </label>
                    <input
                      {...register("email", {
                        required: "Email is required",
                        // eslint-disable-next-line no-useless-escape
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      name="email"
                      placeholder="Email"
                      type="email"
                      id="email"
                      className="form-input block w-full border-darkGray border border-solid bg-white flex-grow-1 outline-0 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                      onChange={(e: any) => {
                        setEmail(e?.target?.value);
                        setValue("email", e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    <p className="text-red text-sm">{errors?.email?.message}</p>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="password" className="block mb-1 text-sm">
                      Password
                      <span className="pl-[4px] text-red">*</span>
                    </label>
                    <input
                      {...register("password", { required: true })}
                      name="password"
                      placeholder="Password"
                      type={showPassword}
                      id="password"
                      className="form-input block w-full border-darkGray border border-solid bg-white outline-0 flex-grow-1 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                      onKeyDownCapture={handleKeyPress}
                      onChange={(e: any) => {
                        setValue("password", e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    <p className="text-red text-sm">
                      {errors.password && "Password is required"}
                    </p>
                  </div>
                </>
                <div>
                  <button
                    type="submit"
                    className="text-white rounded-2xl px-4 py-2 w-full bg-green"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}

            {showMailPass == "Mobile" && (
              <form
                onSubmit={handleSubmit(onMobileSubmit)}
                className="max-w-sm mx-auto mt-10"
              >
                <div className="mb-4">
                  {!showOtpField && (
                    <label htmlFor="email" className="block mb-1 text-sm">
                      Mobile
                      <span className="pl-[4px] text-red">*</span>
                    </label>
                  )}
                  {!showOtpField && !resendOtpDisabled ? (
                    <>
                      <PhoneInput
                        {...register("mobile", {
                          required: "Mobile is required",
                        })}
                        inputProps={{
                          name: "mobile",
                          required: true,
                          autoFocus: true,
                        }}
                        inputStyle={{ width: "100%" }}
                        placeholder="Mobile"
                        country={"us"}
                        value={mobile}
                        onChange={(e: any) => {
                          setMobile(e);
                          setValue("mobile", e, { shouldValidate: true });
                        }}
                      />
                      {errors.mobile && (
                        <p className="text-red text-sm">
                          {errors.mobile.message}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <label htmlFor="sms" className="block mb-1 text-sm">
                        SMS
                        <span className="pl-[4px] text-red">*</span>
                      </label>
                      <input
                        {...register("sms", {
                          required: "SMS is required",
                        })}
                        name="sms"
                        placeholder="SMS"
                        type="number"
                        id="sms"
                        className="form-input block w-full border-darkGray border border-solid bg-white flex-grow-1 outline-0 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                      />
                      {errors.sms && (
                        <p className="text-red text-sm">{errors.sms.message}</p>
                      )}
                    </>
                  )}

                  {showOtpField ? (
                    <>
                      <button
                        className="text-white rounded-2xl px-4 py-2 w-25 mt-3 bg-green"
                        type="button"
                        disabled={resendOtpDisabled}
                        onClick={() => {
                          setResendOtpDisabled(true);
                          setTimer(60);
                          sendCode();

                          // Enable the button after 60 seconds
                          setTimeout(() => {
                            setResendOtpDisabled(false);
                          }, 60000);
                        }}
                      >
                        Resend SMS {resendOtpDisabled && `(${timer}s)`}
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {!showOtpField && (
                  <div>
                    <button
                      disabled={
                        mobile?.length < 11 || mobile == undefined
                          ? true
                          : false
                      }
                      type="submit"
                      className="text-white rounded-2xl px-4 py-2 w-full bg-green"
                      onClick={() => sendCode()}
                    >
                      Submit
                    </button>
                  </div>
                )}
                {showOtpField && (
                  <div>
                    <button
                      type="submit"
                      className="text-white rounded-2xl px-4 py-2 w-full bg-green"
                    >
                      Submit
                    </button>
                  </div>
                )}
              </form>
            )}

            <div className="flex justify-center items-center flex-col mt-8">
              <div className="flex items-center justify-center space-x-2 mb-6">
                <button
                  onClick={() => Googlelogin()}
                  className="flex items-center bg-transparent hover:bg-blue-500 text-blue-700 hover:text-#808080 py-2 px-2 border border-grey hover:border-black rounded"
                >
                  <span className="pr-2">
                    {" "}
                    <FcGoogle size={18} />
                  </span>{" "}
                  Sign in with Google{" "}
                </button>
              </div>
              <div className="flex items-center justify-center space-x-2 mb-4 w-[250px]">
                <MyAppleSigninButton
                  onError={(error: any) => console.error(error)}
                  onSuccess={(response: any) => {
                    setLocalStorage(
                      "APPLE_LOGIN",
                      response?.authorization.id_token
                    );
                    appleLogin();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
