import Reactm, { useRef, useState } from 'react';
import Waveform from "../Waveform";
import PlayList from "./Playlist";
import { Link } from 'react-router-dom'

function Notracks(props: any) {
    // console.log("notrackProps ><>", props);
    var tagcloud = "tagcloud";
    const [all, setAll]: any = useState(false);
    const tracks: any = [
        // {
        //     id: 0,
        //     title: "Two Pianos Op.56b",
        //     author:"Brahms: St Anthony Chorale - Theme",
        //     url:
        //         "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
        // },
        // {
        //     id: 1,
        //     title: "Voice (Clarinet) & Piano",
        //     author:"Franz Schubert's Ständchen",
        //     url:
        //         "https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3"
        // },
        // {
        //     id: 2,
        //     title: "Voice (Clarinet) & Piano",
        //     author:"Franz Schubert's Ständchen",
        //     url:
        //         "https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3"
        // },
        // {
        //     id: 3,
        //     title: "Voice (Clarinet) & Piano",
        //     author:"Franz Schubert's Ständchen",
        //     url:
        //         "https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3"
        // },
        // {
        //     id: 4,
        //     title: "Voice (Clarinet) & Piano",
        //     author:"Franz Schubert's Ständchen",
        //     url:
        //         "https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3"
        // },
        // {
        //     id: 5,
        //     title: "Voice (Clarinet) & Piano",
        //     author:"Franz Schubert's Ständchen",
        //     url:
        //         "https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3"
        // },
    ];

    if (props.cat == "authors") {
        props.data.map((data: any, index: any) => { tracks.push({ id: data._id, isAuthor: true, count: data.count, title: data.name, author: data.categoryName, url: data.audio, cover: data.avatar, description: data.description }) })
    }
    else {
        props.data.map((data: any, index: any) => { tracks.push({ id: data._id, isAuthor: false, count: data.count, title: data.name, author: data.categoryName, url: data.audio, cover: data.cover, description: props?.data[0]?.description, avatar: data.avatar }) })
    }

    const [selectedTrack, setSelectedTrack]: any = useState(tracks[0]);
    if (tracks.length <= 5) {
        tagcloud = "hidden"
    }
    return (
        <>
            <div className="grid gap-4 mb-6 md:grid-cols-3 lg:grid-cols-4 grid-cols-2 pt-[30px]">
                {all ? tracks.map((track: any) => (
                    <Link to="/subcategory" state={{ track: track }}>
                        <div className="relative">
                            <div className="p-6 rounded-lg dark:bg-gray-800 dark:border-gray-700 text-center">
                                <div className="w-full">
                                    <img src={process.env.REACT_APP_UPLOAD_URL + track.cover} alt={""} style={{ height: '100px', width: '100px', margin: '0 auto',borderRadius:'15px' }}>

                                    </img>
                                    {/* <Waveform url={selectedTrack.url} /> */}
                                </div>
                                <div>
                                    <PlayList
                                        className="w-full"
                                        track={track}
                                        selectedTrack={selectedTrack}
                                        setSelectedTrack={setSelectedTrack}
                                    />
                                </div>
                            </div>
                        </div>
                    </Link>
                )) : tracks.slice(0, 4).map((track: any) => (
                    <Link to="/subcategory" state={{ track: track }}>
                        <div className="relative">
                            <div className="p-3 md:p-6 rounded-lg dark:bg-gray-800 dark:border-gray-700 text-center">
                                <div className="w-full mb-2">
                                    <img src={ process.env.REACT_APP_UPLOAD_URL + track.cover} alt={""} style={{ height: '100px', width: '100px', margin: '0 auto',borderRadius:'15px' }} />

                                    {/* <Waveform url={selectedTrack.url} /> */}
                                </div>
                                <div>
                                    <PlayList
                                        className="w-full"
                                        track={track}
                                        selectedTrack={selectedTrack}
                                        setSelectedTrack={setSelectedTrack}
                                    />
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
            {
                all ? <div className={tagcloud} onClick={() => { setAll(!all) }}><button>View less</button></div> : <div className={tagcloud} onClick={() => { setAll(!all) }}><button>View all</button></div>
            }
        </>
    )
}
export default Notracks;