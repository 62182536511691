import { configureStore } from "@reduxjs/toolkit";
import { loginSliceReducer } from "../feature/Authentications/LoginPage-Slice";
import searchReducer from "../feature/Authentications/searchSlice";
import profileReducer from "../feature/Authentications/profileSlice";
import { guestLoginSliceReducer } from "../feature/Authentications/GuestLogin-SLice";
import { loginMobileSliceReducer } from "../feature/Authentications/LoginMobile-Slice";

const store = configureStore({
    reducer: {
        loginSlice: loginSliceReducer,
        search: searchReducer,
        profile: profileReducer,
        guestLogin: guestLoginSliceReducer,
        loginMobile:loginMobileSliceReducer
    }
})

export default store