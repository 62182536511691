import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiEndpoint } from "../../config/apiEndpoint";
import { http } from "../../config/http";
import {
  setLocalStorage,
  showToastErrorMessage,
  showToastSuccessMessage,
} from "../../utils/utils";


const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,

};

export const googlelogin: any = createAsyncThunk(
  "GoogleloginSlice",
  async ({ token }: any) => {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASE_URL}${apiEndpoint.authentication.googlelogin}`, {
        token: token
      },
      );

      if (response?.data?.success == true) {
        const accessToken = response?.data?.token;
        setLocalStorage("accessToken", accessToken);
        showToastSuccessMessage("Login Successfully");
        return response;
      } else {
        throw new Error("Invalid response status");
      }
    } catch (error: any) {
      showToastErrorMessage('Invalid user or password.');
      throw error; // Re-throw the error to be caught by rejected case
    }
  }
);

export const GoogleloginSlice = createSlice({
  name: "GoogleloginSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(googlelogin.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(googlelogin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(googlelogin.rejected, (state) => {
      state.isError = true;
      state.isSuccess = false;
    });
  },
});

export const googleLoginSliceReducer = GoogleloginSlice.reducer;
