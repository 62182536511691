import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiEndpoint } from "../../config/apiEndpoint";
import { http } from "../../config/http";
import {
  setLocalStorage,
  showToastErrorMessage,
  showToastSuccessMessage,
} from "../../utils/utils";

interface ILogin {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean;
  mobile?: any
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  mobile:''
};

export const sendSMS: any = createAsyncThunk(
  "sendsmsSlice",
  async ({ mobile }: ILogin) => {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASE_URL}${apiEndpoint.authentication.sendCode}`,
        {
          phone:`${'+'}${mobile} `,
          
        }
      );
      if(response?.data?.success == true){
        showToastSuccessMessage('SMS sent successfully')
      }
      
    } catch (error: any) {
      showToastErrorMessage('Invalid SMS')
      throw error; // Re-throw the error to be caught by rejected case
    }
  }
);

export const senCodeSlice = createSlice({
  name: "sendsmsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendSMS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendSMS.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(sendSMS.rejected, (state) => {
      state.isError = true;
    });
  },
});

export const sendCodeSliceReducer = senCodeSlice.reducer;
