import React, { useEffect, useState } from "react";
import { FaCamera } from "react-icons/fa";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { editProfile } from "../feature/Authentications/profileSlice";
import { getLocalStorage, setLocalStorage, showToastSuccessMessage } from "../utils/utils";
import { useNavigate } from "react-router";
import user from '../../src/user.png'

const EditProfile = () => {
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const email = localStorage.getItem('email');
  const avatar = localStorage.getItem('avatar');
  const accessToken = localStorage.getItem('accessToken');

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [profile, setProfile]: any = useState({
    firstName: firstName,
    email: email,
    lastName: lastName,
    image: null,
  });

  const userDetails = useSelector((state: any) => state?.profile?.profile?.user);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setProfile((prevProfile: any) => ({
      ...prevProfile,
      [name]: value,
    }));
  };
  
  const clear = () =>{
    // userDetails.firstName = '1',
    // userDetails.lastName = '1',
    // userDetails.avtar = '1',
    // userDetails.email = '1'
  }
useEffect(()=>{
  clear();
},[accessToken])

  const handleImageChange = (e: any) => {
    const selectedImage = e.target.files[0];
    setProfile((prevProfile: any) => ({
      ...prevProfile,
      image: selectedImage,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(profile.image);

    const formData = new FormData();

    formData.append('avatar', profile.image)
    formData.append('firstName', profile.firstName);
    formData.append('lastName', profile.lastName);

    if (profile.firstName && profile.lastName)
      dispatch(editProfile(formData)).then((val:any) => {
        if(val?.meta.requestStatus === "fulfilled"){
          showToastSuccessMessage('Profile updated.')
          setLocalStorage("firstName", profile?.firstName);
          setLocalStorage("lastName", profile?.lastName);
          setLocalStorage("avatar", profile?.image);
          setLocalStorage("email", profile?.email);
        }        
      })
  };
  if (userDetails) {
    userDetails?.firstName && setLocalStorage("firstName", userDetails?.firstName);
    userDetails?.lastName && setLocalStorage("lastName", userDetails?.lastName);
    userDetails?.avatar && setLocalStorage("avatar", userDetails?.avatar);
  }

  const token = getLocalStorage('accessToken')
  const guestToken = getLocalStorage('GUEST_LOGIN')
  useEffect(() => {
    if (!token) {
      navigate('/')
    }

  }, [token, guestToken])

  return (
    <>
      <Header />
      <div className="mx-auto text-center edit-profile-wrapper mt-[150px] mb-10 md:mb-[0]">
        <h2 className="text-2xl font-bold mb-4 mt-5">Edit Profile</h2>
        <form onSubmit={handleSubmit}>
          <table align="center" className="max-w-[650px] table-outer" >
            <tbody>
              <tr>
                <td className="-translate-x-16 profile-wrapper">
                  <label className="block mb-2 text-lg">
                    Profile Image
                  </label>
                  <div className="relative rounded-full w-64 h-64 mx-auto mb-4 overflow-hidden profile-image">
                    {profile.image ? (
                      <img
                        src={URL.createObjectURL(profile.image)}
                        alt="Selected"
                        className="w-full h-full object-cover"
                      />
                    ) : avatar ? (
                      <div className="relative rounded-full w-64 h-64 mx-auto mb-4 overflow-hidden bg-grey">
                        <img
                          src={avatar == null || avatar == "null" ? user : process.env.REACT_APP_UPLOAD_URL + avatar}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                    ) : <div className="w-full h-full bg-e0e0e0 flex items-center justify-center bg-grey">
                      <img
                          src={avatar == null || avatar == "null" ? user : process.env.REACT_APP_UPLOAD_URL + avatar}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                    </div>}
                    <label
                      htmlFor="image"
                      className="absolute right-2 top-40 w-12 h-10 bg-white rounded-full flex items-center justify-center cursor-pointer shadow-md camera-icon"
                    >
                      <FaCamera size={24} />
                    </label>
                  </div>
                  <input
                    className="hidden"
                    type="file"
                    id="image"
                    name="image"
                    onChange={handleImageChange}
                  />
                </td>
                <td>
                  <table cellPadding={'10px'} align="left" className="edit-profile-form">
                    <tbody>
                      <tr>
                        <td >
                          <label className="block text-lg" htmlFor="firstName">
                            First Name
                            <span className="pl-[4px] text-red">*</span>
                          </label>
                        </td>
                        <td>
                          <input
                            className="form-input w-full border-darkGray border border-solid bg-white flex-grow-1 outline-0 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={profile?.firstName}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td >
                          <label className="sblock text-lg" htmlFor="lastName">
                            Last Name
                            <span className="pl-[4px] text-red">*</span>
                          </label>
                        </td>
                        <td>
                          <input
                            className="form-input w-full border-darkGray border border-solid bg-white outline-0 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                            id="lastName"
                            name="lastName"
                            value={profile?.lastName}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label className="block text-lg" htmlFor="email">
                            Email
                          </label>
                        </td>
                        {email == null || email == "null" || email == undefined || email == "undefined" ? 
                        <td>
                        <input
                          className="form-input w-full border-darkGray border border-solid bg-white outline-0 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                          type="email"
                          id="email"
                          name="email"
                          value={profile?.email == undefined || profile?.email == "undefined" ? "" : profile?.email}
                          onChange={handleChange}
                        />
                      </td> : 
                      <td>
                      <input
                        disabled
                        className="form-input w-full border-darkGray border border-solid bg-white outline-0 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                        type="email"
                        id="email"
                        name="email"
                        value={profile?.email}
                        onChange={handleChange}
                      />
                    </td>}
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

          {profile?.firstName == null || profile?.firstName == "" || profile?.lastName == null || profile?.lastName == "" ?
          <button
          disabled
          className="px-4 py-2 bg-grey text-white rounded-md"
          type="submit"
        >
          Save Change
        </button> :
        <button
        className="px-4 py-2 bg-green text-white rounded-md hover:bg-blue"
        type="submit"
      >
        Save Change
      </button>}
        </form>
      </div>

    </>
  );
};

export default EditProfile;
