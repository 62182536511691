import React, { useEffect, useState } from 'react';
import un from '../un.png'
import heart from '../heart.png'
import buy from '../icons8-shopping-cart-50.png'
import AudioPlayer from '../Components/AudioPlayer';
import share from '../share.png'
import heartfill from '../heartfill.png'
import Header from '../Components/Header';
import { useLocation } from 'react-router-dom'
import { fetchRequest } from '../Api/fetchRequest';
import { showToastSuccessMessage } from '../utils/utils';
import { getLocalStorage } from '../utils/utils';
import { useNavigate } from "react-router";

const Recording = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const token = getLocalStorage('accessToken')
    const { track } = location.state
    const [isToggled, setToggle]: any = useState(false);
    console.log('track', track)
    
    const getData = async () => {
        let resp = await fetchRequest('POST', 'page/favorite', undefined);
        // loading && setLoading(false);
        resp?.posts?.map((item: any) => {
            if (item?._id == track?.id) {
                setToggle(true)
            }
        });
    };

    const handleToggle = () => {
        if (token)
        {
            setToggle(!isToggled);
            fetchRequest('POST', 'user/favorite', { ID: track?.id }, true)
                .then((resp => {
                    getData()
                    if (!isToggled) {
                        showToastSuccessMessage("Added in Favourite");
    
                    } else {
                        showToastSuccessMessage("Removed from Favourite");
                    }
                }));
        }
        else{
            navigate('/')
        }
    };

    useEffect(() => {
        setToggle(false);
        getData();

    }, [track]);

    const copyLink = async () => {
        var link: any = track?.title + "\n" + process.env.REACT_APP_UPLOAD_URL + "audio/" + track?.audio
        try {
            await navigator?.clipboard?.writeText(link);
            showToastSuccessMessage("Link copied to clipboard");
        } catch (err: any) {
            showToastSuccessMessage('Failed to copy: ' + err);
            throw err
        }
    }
    var props = { track: process.env.REACT_APP_UPLOAD_URL + "audio/" + track?.audio }
    return (
        <>
            <Header />
            <div>
                <div className="container mx-auto p-4 text-center mt-[140px]">
                    <img src={process.env.REACT_APP_UPLOAD_URL + track.cover} alt="Center Image" className="mx-auto h-48 mb-6" />

                    <div className="flex justify-center items-center">
                        <h2 className="text-2xl font-bold mb-4">{track.title}</h2>
                    </div>

                    <p className="text-slate-500 mb-4 italic">{track.author}</p>
                    <p className="mb-4">{track.authorName}</p>
                    <div>
                        <AudioPlayer {...props} />
                    </div>
                    <p className="text-slate-500 mb-6">
                        {track.description}
                    </p>

                    {/* <button className="bg-gray-900 hover:bg-gray-900 text-white font-bold py-2 px-12 rounded mb-8  bg-black">
                    <div className="flex">
                        <img src={buy} alt="Center Image" className="mx-auto filter brightness-200  invert h-5" />
                        <span> LICENSE</span>
                    </div>
                </button> */}



                    <div className="flex justify-evenly" >
                        {/* <div>
                            <p>2:47</p>
                            <p>Minutes</p>
                        </div> */}

                        <div >
                            <button onClick={() => handleToggle()}>
                                {!isToggled ? <img src={heart} alt="Center Image" className="mx-auto  mb-4 w-[30px]" />
                                    : <img src={heartfill} alt="Center Image" className="mx-auto  mb-4 w-[30px]" />
                                }
                            </button>
                            <p>Mark as Favorite</p>
                        </div>
                        <div>
                            <button onClick={() => copyLink()}>
                            <img src={share} alt="Center Image" className="mx-auto  mb-4" />
                            </button>
                            <p>Share</p>
                        </div>
                    </div>
                </div>

            </div>
        </>

    );
};

export default Recording;