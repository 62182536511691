import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdClear } from 'react-icons/md';
import Header from "../../Components/Header"

interface IFormInputs {
    email: string;
}

const ForgotPasswordPage = () => {

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setValue
    } = useForm<IFormInputs>();

    const [email, setEmail] = useState('');

    const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
        // console.log("data", data);
    };

    return (
        <>
            <div className="bg-gray-100">
                <Header />
                <div className="min-h-screen flex items-center justify-center">
                    <div className="max-w-md w-full mx-auto p-8 bg-white rounded shadow-xl">
                        <div className="flex flex-col w-full mb-4">
                            <div className="text-lg leading-6 pt-2 font-bold">
                                Forgot Password
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className="max-w-sm mx-auto mt-10">
                            <div className="mb-4">
                                <label htmlFor="email" className="block mb-1 text-sm">Email</label>
                                <div className="relative">
                                    <input
                                        {...register('email', {
                                            required: 'Email is required',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: 'Invalid email address',
                                            },
                                        })}
                                        name="email"
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e: any) => {
                                            setEmail(e.target.value);
                                            setValue('email', e.target.value, { shouldValidate: true });
                                        }}
                                        className="w-full p-2 pr-8 border border-gray-300 rounded"
                                        placeholder="Email"
                                    />
                                    {email && ( // Show the cross button icon if email has a value
                                        <MdClear
                                            className="absolute top-3 right-3 text-gray-500 cursor-pointer"
                                            onClick={() => {
                                                setEmail('');
                                                setValue('email', '');
                                            }}
                                        />
                                    )}
                                </div>
                                <p className="text-red text-sm">
                                    {errors?.email?.message}
                                </p>
                            </div>
                            <div className="flex justify-start space-x-2">
                                <button
                                    type="submit"
                                    className="inline-flex justify-center px-3 py-2 mr-1 text-sm font-medium text-white bg-green bg-logo border border-transparent shadow-sm rounded-md focus:outline-none"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPasswordPage