import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiEndpoint } from "../../config/apiEndpoint";
import { http } from "../../config/http";
import {
    setLocalStorage,
    showToastSuccessMessage,
} from "../../utils/utils";

interface ILogin {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    formData: any
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const profileDetails: any = createAsyncThunk(
    "ProfileDetailsSlice",
    async ({ formData }: ILogin) => {
        try {
            const response = await http.post(
                `${process.env.REACT_APP_BASE_URL}${apiEndpoint.authentication.profileDetails}`, formData
            );            
            if (response?.data?.success == true) {
                 setLocalStorage("firstName",response?.data?.user?.firstName);
                 setLocalStorage("lastName",response?.data?.user?.lastName);
                 setLocalStorage("email",response?.data?.user?.email);
                 setLocalStorage("avatar",response?.data?.user?.avatar);
                setLocalStorage('accessToken',response?.data?.token)
                showToastSuccessMessage("Register successfully");
                return response;
            } else {
                throw new Error("Invalid response status");
            }
        } catch (error: any) {
            //   showToastErrorMessage('Invalid user or password.');
            throw error; // Re-throw the error to be caught by rejected case
        }
    }
);

export const profiledetailsSlice = createSlice({
    name: "ProfileDetailsSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(profileDetails.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(profileDetails.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(profileDetails.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const profileSliceReducer = profiledetailsSlice.reducer;
