import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiEndpoint } from "../../config/apiEndpoint";
import { http } from "../../config/http";
import {
  setLocalStorage,
  showToastErrorMessage,
  showToastSuccessMessage,
} from "../../utils/utils";

interface ILogin {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const Guestlogin: any = createAsyncThunk(
  "GuestloginSlice",
  async () => {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASE_URL}${apiEndpoint.authentication.guestLogin}`
      );
       
      if (response?.data?.success == true) {
        const accessToken = response?.data?.token;
        setLocalStorage("GUEST_LOGIN", accessToken);
        return response;
      } else {
        throw new Error("Invalid response status");
      }
    } catch (error: any) {
    //   showToastErrorMessage('Invalid user or password.');
      throw error; // Re-throw the error to be caught by rejected case
    }
  }
);

export const GuestloginSlice = createSlice({
  name: "GuestloginSlice",
  initialState,
  reducers: {
  	isUserlogin: (state) => {
			state.isLoading = false
			state.isSuccess = false
			state.isError = false
		},
  },
  extraReducers: (builder) => {
    builder.addCase(Guestlogin.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(Guestlogin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(Guestlogin.rejected, (state) => {
      state.isError = true;
			state.isSuccess = false
    });
  },
});
export const { isUserlogin } = GuestloginSlice.actions

export const guestLoginSliceReducer = GuestloginSlice.reducer;
