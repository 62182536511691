export const apiEndpoint = {
    authentication:{
        login: '/user/signIn',
        googlelogin:'/auth/google',
        applelogin:'/auth/appleWeb',
        guestLogin:'/auth/guest',
        mobile:'/auth/check',
        sendCode:'/auth/sendcode',
        register:'/user/signUp',
        search :'/page/search',
        profileDetails:'/auth/signup'
    }
}
