import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { fetchRequest } from "../Api/fetchRequest";
import { useLocation } from 'react-router-dom'
import Waveform from "../Waveform";
import PlayList from "./Playlist";
import { Link } from 'react-router-dom'
import bgImage from '../../src/icons8-play-67.png'
import Header from "./Header";
import { set } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import Linkify from 'react-linkify';

function Subcategory() {

  const location = useLocation()
  const { track } = location.state;
  // console.log("track .,.", track);
  const [isAuthor, setIsAuthor] = useState(track.isAuthor);
  const title = track.title;
  var tagcloud = "tagcloud";
  const [all, setAll]: any = useState(false);
  const [data, setData]: any = useState();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage]: any = useState(1);
  const getData = async () => {
    setLoading(true);
    var body: any = '';
    if (isAuthor) {
      body = { "page": currentPage, "search": track.id, "author": track.id }
    }
    else {
      body = { "page": currentPage, "search": track.id }
    }
    let resp = await fetchRequest('POST', 'page/posts', body, true);
    if (data?.length > 0) {
      !!resp && setData([...data, ...resp.posts]);
    } else {
      !!resp && setData(resp.posts);
    }
    setLoading(false);

  };

  const tracks: any = [];
  useEffect(() => {
    getData();
  }, [currentPage]);

  // console.log(tracks, 111);

  data?.map((data: any, index: any) => { tracks.push({ id: data?._id, title: data?.name, author: data?.categoryName, audio: data?.audio, cover: data?.cover, duration: data?.duration, description: data?.description, authorName: data?.authorName, favorite: data?.favorite }) })
  const [selectedTrack, setSelectedTrack]: any = useState(tracks[0]);
  // console.log("tracks up", tracks);

  const pagenumber = Math.ceil(track.count / 15)

  return (
    <>
      <Header />
      <div className="md:w-[75%] w-[90%] mx-auto mb-[50px] mt-[180px] md:mt-[130px]  block">
        <div className="flex gap-4">
          <h4 className="text-xl font-medium">{title}</h4><hr />
        </div>
        <h5 className="text-[#808080]">{track?.count} Recordings</h5>
        {isAuthor ?
        <Linkify>
          <div className="pt-10"><p className="text-2xl font-medium pb-2">About this Author : </p>
            <p>{track?.description}</p>
          </div></Linkify> : <div className="pt-10"><p className="text-2xl font-medium pb-2">About this Pack : </p>
            <p className="text-[#808080]">{track?.description}</p>
          </div>}

        <div className="grid gap-4 mb-6 md:grid-cols-3 lg:grid-cols-4 grid-cols-2 h-[182.34px] pt-[30px] h-auto">
          {tracks?.map((track: any) =>
          (
            <Link to="/details" state={{ track: track }}>
              <div className="relative">
                <div className="p-6 rounded-lg dark:bg-gray-800 dark:border-gray-700 text-center">
                  <div className="w-full">
                    <img src={ process.env.REACT_APP_UPLOAD_URL + track?.cover} alt={""} style={{ height: '150px', width: '150px', margin: '0 auto' }} />

                    <img src={bgImage} alt={""} style={{ height: '20px', width: '20px', margin: '0 auto' }} />
                  </div>
                  <div>
                    <PlayList
                      className="w-full"
                      track={track}
                      selectedTrack={selectedTrack}
                      setSelectedTrack={setSelectedTrack}
                    />
                  </div>
                </div>
              </div>

            </Link>
          ))}
        </div>
        {/* {
           all ? <div className={tagcloud} onClick={() => { setAll(!all) }}><button>View less</button></div> : <div className={tagcloud} onClick={() => { setAll(!all) }}><button>View all</button></div>
        } */}
        {
          pagenumber > currentPage &&
          <div className="flex justify-center">
            <button className={!loading ? "bg-grey text-gray-800 font-bold py-2 px-4 rounded-l inline-block h-[48px]" : ""} onClick={() => setCurrentPage(currentPage + 1)}>
              {loading ? <span><ClipLoader color="#36D7B7" loading={true} size={30} /></span> : <span>Show more...</span>
              }
            </button>

          </div>
        }
      </div>
    </>
  )
}

export default Subcategory;