import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Header from '../../Components/Header'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { FaCamera } from 'react-icons/fa'
import { registerApi } from '../../feature/Authentications/RegisterPage-Slice';
import { useDispatch } from 'react-redux';
import { loginMobile } from '../../feature/Authentications/LoginMobile-Slice';
import { sendSMS } from '../../feature/Authentications/SendCode-Slice';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import MyAppleSigninButton from './AppleLogin';
import { useNavigate } from 'react-router';
import { profileDetails } from '../../feature/Authentications/ProfileDetails-Slice';
import BeforeLoginHeader from '../../Components/Header2';
import bgImage from '../../login_bg.jpg'
import { setLocalStorage, showToastErrorMessage } from '../../utils/utils';
import axios from 'axios';
import { googlelogin } from '../../feature/Authentications/GoogleLogin-Slice';
import { FcGoogle } from 'react-icons/fc';

interface IFormInputs {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    mobile?: number;
    sms?: number;
    profile_image?: any
}

const RegisterPage = () => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<IFormInputs>();

    const [showPassword, setShowPassword] = useState("password");
    const [email, setEmail] = useState('');
    const [firsttName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile]: any = useState();
    const [resendOtpDisabled, setResendOtpDisabled] = useState(false);
    const [timer, setTimer] = useState(15);
    const [showMailPass, setShowMailPass] = useState('Email')
    const [showOtpField, setShowOtpField] = useState(false);
    const [profileImage, setProfileImage]: any = useState(null);
    const [userDetail, setUserDetail] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onSubmit = (data: any) => {
        dispatch(registerApi({ data })).then((val: any) => {
            if (val?.meta.requestStatus) {
                navigate('/home')
            }
        }
        )
        // if (showOtpField) {
        // } else {
        //     setShowOtpField(false);
        //     setFirstName(data.first_name);
        //     setLastName(data.last_name);
        //     setProfileImage(data.profile_image);
        // }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
    };

    const handleEmail = (val: any) => {
        setShowMailPass(val)
    }

    const onMobileSubmit = (e: any) => {
        // setShowOtpField(!showOtpField)
        dispatch(loginMobile({ e })).then((val: any) => {
            console.log("VALUE",val);

            if(val?.payload?.data?.token){
                setLocalStorage('accessToken',val?.payload?.data?.token)
                navigate('/home')
            }
            
            if (val?.payload?.data.valid == true) {
                // navigate("/home");
                setUserDetail(!userDetail)

            }
            else {
                showToastErrorMessage("Invalid verification code")
            }

        })

    }

    useEffect(() => {
        let intervalId: any;

        if (resendOtpDisabled) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [resendOtpDisabled]);


    const handleImageChange = (e: any) => {
        const selectedImage = e.target.files[0];
        setProfileImage(selectedImage)
    };

    const sendCode = () => {
        dispatch(sendSMS({ mobile }))
        setShowOtpField(true)

    }

    const usersDetails = (data: any) => {

        const formData = new FormData()

        formData.append('firstName', data?.first_name);
        formData.append('lastName', data?.last_name);

        if (data?.email) {
            formData.append('email', data?.email)
        }
        if (profileImage) {
            formData.append('avatar', profileImage)
        }
        formData.append('phone', `+${mobile}`)
        dispatch(profileDetails({ formData })).then((val: any) => {
            if (val?.payload?.data?.success == true) {
                setLocalStorage('accesstoken', val?.payload?.data?.token)
                setLocalStorage("email", val?.payload?.data?.email);
                navigate('/home')
            } else {
                navigate('/register')
            }

            // if (val?.meta.requestStatus == "rejected") {
            //     navigate('/')
            // }
        })
    }
    const Googlelogin = useGoogleLogin({
        onSuccess: async ({ code }) => {
            const tokens = await axios.post(`${process.env.REACT_APP_BASE_URL}${'/auth/googleWeb'}`, {
                code,
            }).then((val: any) => {
                if (val?.data?.id_token) {
                    dispatch(googlelogin({ token: val?.data?.id_token })).then((val: any) => {
                        if (val?.meta?.requestStatus == "fulfilled") {
                            navigate('/home')

                        }
                    })
                } else {
                    return false
                }
            }
            )

        },
        flow: 'auth-code',
    });

    return (
        <>
            <div className="bg-gray-100 ">
                <BeforeLoginHeader />
                <div className="min-h-screen flex items-center justify-center"
                    style={{
                        backgroundImage: `url(${bgImage})`
                    }}>
                    <div className="max-w-md w-full mx-auto p-3 md:p-8 bg-white rounded shadow-xl">
                        <div className="flex flex-col w-full mb-4">
                            <div className="text-center leading-6 pt-2 font-bold">
                                Register
                            </div>
                        </div>

                        <div className='flex gap-x-[-5px] border rounded-2xl '>
                            <button className={`text-black  px-4 py-2 w-full rounded-2xl ${showMailPass === 'Email' && 'bg-green text-white'}`} onClick={() => handleEmail('Email')}>Email</button>
                            <button className={`text-black  px-4 py-2 w-full   ml-[-30px] ${showMailPass === 'Mobile' && 'bg-green text-white'}  rounded-2xl`} onClick={() => handleEmail('Mobile')}>Mobile</button>
                        </div>

                        {showMailPass === 'Email' &&
                            <form onSubmit={handleSubmit(onSubmit)} className="max-w-sm mx-auto mt-10">
                                <>
                                    <div className="mb-4">
                                        <label htmlFor="email" className="block mb-1 text-sm">Email
                                            <span className="pl-[4px] text-red">*</span></label>
                                        <input
                                            {...register("email", {
                                                required: 'Email is required',
                                                // eslint-disable-next-line no-useless-escape
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Invalid email address"
                                                }
                                            })}
                                            name="email"
                                            placeholder="Email"
                                            type="email"
                                            id="email"
                                            className="form-input block w-full border-darkGray border border-solid bg-white flex-grow-1 outline-0 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                                            onChange={(e: any) => { setEmail(e?.target?.value); setValue("email", e.target.value, { shouldValidate: true }) }}
                                        />
                                        <p className="text-red text-sm">
                                            {errors?.email?.message}
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="password" className="block mb-1 text-sm">Password
                                            <span className="pl-[4px] text-red">*</span></label>
                                        <input
                                            {...register("password", { required: true })}
                                            name="password"
                                            placeholder="Password"
                                            type={showPassword}
                                            id="password"
                                            className="form-input block w-full border-darkGray border border-solid bg-white outline-0 flex-grow-1 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                                            onKeyDownCapture={handleKeyPress}
                                        />
                                        <p className="text-red text-sm">
                                            {errors.password && "Password is required"}
                                        </p>
                                    </div>
                                    <button type="submit" className="text-white rounded-2xl px-4 py-2 w-full bg-green">
                                        Submit
                                    </button>
                                </>
                            </form>

                        }

                        {showMailPass == 'Mobile' &&
                            <form onSubmit={handleSubmit(onMobileSubmit)} className="max-w-sm mx-auto mt-10">
                                <div className="mb-4">
                                    {!showOtpField && (

                                        <label htmlFor="email" className="block mb-1 text-sm">Mobile
                                            <span className="pl-[4px] text-red">*</span></label>
                                    )}
                                    {!showOtpField && !resendOtpDisabled ? (
                                        <>
                                            <PhoneInput
                                                {...register('mobile', {
                                                    required: true,
                                                })}
                                                inputProps={{
                                                    name: 'mobile',
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                                inputStyle={{ width: '100%' }}
                                                placeholder="Mobile"
                                                country={'us'}
                                                value={mobile}
                                                onChange={(e: any) => {
                                                    setMobile(e);
                                                    setValue('mobile', e, { shouldValidate: true });
                                                }}
                                            />
                                            {errors.mobile && <p className="text-red text-sm">{errors.mobile.message}</p>}
                                        </>
                                    ) : (
                                        <>
                                            {showOtpField && !userDetail &&
                                                <>
                                                    <label htmlFor="sms" className="block mb-1 text-sm">
                                                        SMS
                                                        <span className="pl-[4px] text-red">*</span>
                                                    </label>
                                                    <input
                                                        {...register('sms', {
                                                            required: 'SMS is required',
                                                        })}
                                                        name="sms"
                                                        placeholder="SMS"
                                                        type="number"
                                                        id="sms"
                                                        className="form-input block w-full border-darkGray border border-solid bg-white flex-grow-1 outline-0 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                                                    />
                                                    {errors.sms && <p className="text-red text-sm">{errors.sms.message}</p>}

                                                </>}
                                        </>
                                    )}

                                    {showOtpField && !userDetail ? (
                                        <>
                                            <button
                                                className='text-white rounded-2xl px-4 py-2 w-25 mt-3 bg-green'
                                                type="button"
                                                disabled={resendOtpDisabled}
                                                onClick={() => {
                                                    setResendOtpDisabled(true);
                                                    setTimer(60);
                                                    sendCode()

                                                    // Enable the button after 60 seconds
                                                    setTimeout(() => {
                                                        setResendOtpDisabled(false);
                                                    }, 60000);
                                                }}
                                            >
                                                Resend SMS {resendOtpDisabled && `(${timer}s)`}
                                            </button>
                                        </>
                                    ) : ''}

                                </div>
                                {!showOtpField &&
                                    <div>
                                        <button type="button" disabled={
                                            mobile?.length < 11 || mobile == undefined
                                                ? true
                                                : false} className="text-white rounded-2xl px-4 py-2 w-full bg-green" onClick={() => sendCode()}>Submit</button>
                                    </div>
                                }
                                {showOtpField && !userDetail &&

                                    <div>
                                        <button type="submit" className="text-white rounded-2xl px-4 py-2 w-full bg-green">Submit</button>
                                    </div>
                                }
                            </form>
                        }

                        {showOtpField && showMailPass !== 'Email' && userDetail &&
                            <>
                                <form onSubmit={handleSubmit(usersDetails)} className="max-w-sm mx-auto mt-10">
                                    <div className="mb-4">
                                        <label htmlFor="first_name" className="block mb-1 text-sm">First Name</label>
                                        <input
                                            {...register("first_name", { required: true })}
                                            name="first_name"
                                            placeholder="First Name"
                                            type="text"
                                            id="first_name"
                                            className="form-input block w-full border-darkGray border border-solid bg-white flex-grow-1 outline-0 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                                        // onChange={(e) => {

                                        //     setUserDetail((prevUserDetail) => ({
                                        //         ...prevUserDetail,
                                        //         first_name: e.target.value,
                                        //     }))
                                        //     setValue("first_name", e.target.value, { shouldValidate: true })
                                        // }
                                        // }
                                        />
                                        <p className="text-red text-sm">
                                            {errors.first_name && "First Name is required"}
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="last_name" className="block mb-1 text-sm">Last Name</label>
                                        <input
                                            {...register("last_name", { required: true })}
                                            name="last_name"
                                            placeholder="Last Name"
                                            type="text"
                                            id="last_name"
                                            className="form-input block w-full border-darkGray border border-solid bg-white flex-grow-1 outline-0 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                                            defaultValue={lastName}
                                        />
                                        <p className="text-red text-sm">
                                            {errors.last_name && "Last Name is required"}
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="email" className="block mb-1 text-sm">Email</label>
                                        <input
                                            {...register("email", {
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Invalid email address"
                                                }
                                            })}
                                            name="email"
                                            placeholder="Email"
                                            type="email"
                                            id="email"
                                            className="form-input block w-full border-darkGray border border-solid bg-white flex-grow-1 outline-0 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                                        />
                                        <p className="text-red text-sm">
                                            {errors.email && "Email is required"}
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="profile_image" className="block mb-1 text-sm">Profile Image</label>
                                        <div className="relative rounded-full w-32 h-32 mx-auto mb-4 overflow-hidden border-2 border-black">
                                            {profileImage && (
                                                <img
                                                    src={URL.createObjectURL(profileImage)}
                                                    alt="Selected"
                                                    className="w-full h-full object-cover"
                                                />
                                            )}
                                            <label
                                                htmlFor="image"
                                                className="absolute right-2 top-20 w-10 h-10 bg-white rounded-full flex items-center justify-center cursor-pointer shadow-md border-2 border-black"
                                            >
                                                <FaCamera size={18} />
                                            </label>
                                        </div>
                                        <input
                                            accept='image/png, image/jpg, image/jpeg"'
                                            className="hidden"
                                            type="file"
                                            id="image"
                                            name="image"
                                            onChange={(e: any) => handleImageChange(e)}
                                        />
                                    </div>
                                    <button type="submit" className="text-white rounded-2xl px-4 py-2 w-full bg-green">
                                        Submit
                                    </button>
                                </form>
                            </>
                        }


                        <div className="text-center border-b border-b-black leading-[0.03em] pt-5 mb-5 mr-5 w-full"></div>

                        <div className="flex items-center justify-center space-x-2 mb-6">
                            <button onClick={() => Googlelogin()} className="flex items-center bg-transparent hover:bg-blue-500 text-blue-700 hover:text-#808080 py-2 px-2 border border-grey hover:border-black rounded"><span className='pr-2'> <FcGoogle size={18} /></span>  Sign in with Google </button>
                        </div>
                        <div className='flex items-center justify-center space-x-2 mb-6'>
                            <MyAppleSigninButton
                                onError={(error: any) => console.error(error)}
                                onSuccess={(response: any) => console.log(response, 'response')} />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterPage;
