import Reactm, { useEffect, useRef, useState } from 'react';
import PlayList from "./Playlist";
import { Link } from 'react-router-dom'
import bgImage from '../../src/icons8-play-67.png'

function Tracks(props: any) {
    const [tracked, setTracked]: any = useState("");
    const [all, setAll]: any = useState(false);

    const locationName: any =  window.location.href.substring( window.location.href.lastIndexOf("/"));
    var tagcloud = "tagcloud";
    
    const tracks: any = [
        // {
        //     id: 0,
        //     title: "Two Pianos Op.56b",
        //     author: "Brahms: St Anthony Chorale - Theme",
        //     url:
        //         "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
        // },
        // {
        //     id: 1,
        //     title: "Voice (Clarinet) & Piano",
        //     author: "Franz Schubert's Ständchen",
        //     url:
        //         "https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3"
        // },
        // {
        //     id: 2,
        //     title: "Voice (Clarinet) & Piano",
        //     author: "Franz Schubert's Ständchen",
        //     url:
        //         "https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3"
        // },
        // {
        //     id: 3,
        //     title: "Voice (Clarinet) & Piano",
        //     author: "Franz Schubert's Ständchen",
        //     url:
        //         "https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3"
        // },
        // {
        //     id: 4,
        //     title: "Voice (Clarinet) & Piano",
        //     author: "Franz Schubert's Ständchen",
        //     url:
        //         "https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3"
        // },
        // {
        //     id: 5,
        //     title: "Voice (Clarinet) & Piano",
        //     author: "Franz Schubert's Ständchen",
        //     url:
        //         "https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3"
        // },
    ];
    if (all || locationName === "/favourites") {
        props.data.map((data: any, index: any) => { tracks.push({ id: data._id, title: data.name, author: data.categoryName, audio: data.audio, cover: data.cover, duration: data.duration, description: data.description, authorName: data.authorName }) })
    }
    else {
        props.data.slice(0, 4).map((data: any, index: any) => { tracks.push({ id: data._id, title: data.name, author: data.categoryName, audio: data.audio, cover: data.cover, duration: data.duration, description: data.description, authorName: data.authorName }) })
    }
    // console.log("tracks", tracks);
    if (tracks.length < 4) {
        tagcloud = "hidden"
    }

    const [selectedTrack, setSelectedTrack]: any = useState(tracks[0]);
    return (
        <>
            <div className="grid gap-4 mb-6 md:grid-cols-3 lg:grid-cols-4 grid-cols-2 pt-[30px]">
                {tracks.map((track: any, index: number) =>
                (
                    <Link to="/details" state={{ track: track }} key={index}>

                        <div className="relative">
                            <div className="md:p-6 p-3 rounded-lg dark:bg-gray-800 dark:border-gray-700 text-center">
                                <div className="w-full">
                                    <img src={process.env.REACT_APP_UPLOAD_URL + track.cover} alt={""} style={{ height: '150px', width: '150px', margin: '0 auto' }} />

                                    <img src={bgImage} alt={""} style={{ height: '20px', width: '20px', margin: '0 auto' }} />

                                    {/* <Waveform url={selectedTrack.url} /> */}
                                </div>
                                <div>
                                    <PlayList
                                        className="w-full"
                                        track={track}
                                        selectedTrack={selectedTrack}
                                        setSelectedTrack={setSelectedTrack}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* <div className="relative">
                        <div className="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                            <div className="w-full">
                                <Waveform url={selectedTrack.url} />
                            </div>
                            <div>
                                <PlayList
                                    className="w-full"
                                    track={track}
                                    selectedTrack={selectedTrack}
                                    setSelectedTrack={setSelectedTrack}
                                />
                            </div>
                        </div>
                    </div> */}
                    </Link>
                ))}
            </div>
            {
                locationName === "/favourites" ? <div className="hidden" onClick={() => { setAll(!all) }}><button>View all</button></div> : all ? <div className={tagcloud} onClick={() => { setAll(!all) }}><button>View less</button></div> : <div className={tagcloud} onClick={() => { setAll(!all) }}><button>View all</button></div>
            }
        </>
    )
}
export default Tracks;