import React, { useState, useEffect } from 'react';
import footImage from "../images/footer.jpg"

const Footer = () => {

    return (
        <>
            <footer className='pt-[5%]'>
                <div className='flex w-full'>
                    {/* <div className="max-w-[25%] w-full">
                    <a href='https://vipassanahawaii.org/' target='_blank'>
                        <img
                            src={Advertise}
                            alt=""
                            className="w-full"
                        /></a>
                    </div> */}
                    <div className="max-w-[100%] w-full">
                        <img src={footImage} className="w-full h-full" alt="Footer" />
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer