import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiEndpoint } from "../../config/apiEndpoint";
import { http } from "../../config/http";
import {
  setLocalStorage,
  showToastErrorMessage,
  showToastSuccessMessage,
} from "../../utils/utils";
import { fetchRequest } from "../../Api/fetchRequest";

interface ILogin {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean;
  e: {
    password: string;
    email: string;
  };
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  email: "",
  password: "",
};

export const login: any = createAsyncThunk(
  "loginSlice",
  async ({ e }: ILogin) => {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASE_URL}${apiEndpoint.authentication.login}`,
        {
          email: e?.email,
          password: e?.password,
        }
      );

      if (response?.data?.success == true) {
        const accessToken = response?.data?.token;
        setLocalStorage("accessToken", accessToken);
        const user: any = await fetchRequest('GET', 'user/info', undefined, accessToken);
        // console.log("info", user);
        if (user.success == true) {
          setLocalStorage("firstName", user?.user?.firstName);
          setLocalStorage("lastName", user?.user?.lastName);  
          setLocalStorage("avatar", user?.user?.avatar);  
        }
        setLocalStorage("email", response?.data?.user?.email);
        showToastSuccessMessage("Login Successfully");
        return response;
      } else {
        throw new Error("Invalid response status");
      }
    } catch (error: any) {
      showToastErrorMessage('Invalid user or password.');
      throw error; // Re-throw the error to be caught by rejected case
    }
  }
);

export const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.email = payload.email;
      state.password = payload.password;
    });
    builder.addCase(login.rejected, (state) => {
      state.isError = true;
      state.isSuccess = false;
    });
  },
});

export const loginSliceReducer = loginSlice.reducer;
