import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import Nature from '../Components/Nature';
import { fetchRequest } from "../Api/fetchRequest";
import { getLocalStorage } from "../utils/utils";
import { useNavigate } from "react-router";
import Header from "./Header";
import Footer from "./Footer";
import Advertise from '../../src/images/VH.jpg'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

function CarouselComponent() {
  var categoryName: any = '';
  const [data, setData] = useState();

  const [loading, setLoading] = useState(true);
  const getData = async () => {
    let resp = await fetchRequest('GET', 'page/home', undefined, true);
    loading && setLoading(false);
    !!resp && setData(resp);
  };


  useEffect(() => {

    getData();

  }, []);

  if (data) {
    Object.keys(data).map((key, i) => {
      categoryName = Object.keys(data);
    });
  }

  const props = {
    "cat": "",
    "data": ""
  }

  const token = getLocalStorage('accessToken')
  const guestToken = getLocalStorage('GUEST_LOGIN')
  const navigate = useNavigate()

  useEffect(() => {
    if (!token && !guestToken) {
      navigate('/')
    }

  }, [token, guestToken])

  return (
    <>
      <Header />

      <div className="mt-[140px]">
        <div className="pb-[50px]">
        <div className="pb-[50px] max-w-[400px] m-auto">
          <a href='https://vipassanahawaii.org/' target='_blank'>
            <center>
            <Tooltip id="my-tooltip" />
            <img
              src={Advertise}
              alt=""
              data-tooltip-id="my-tooltip" data-tooltip-content="Vipassana Hawai'i"
            />
            </center>
            </a></div>
          <p className="banner-text"><span>Aloha!</span> Welcome to our online app. Please feel free to listen to audio recordings of <span>Dhamma talks, guided meditation instructions, and chanting resources</span> from our teachers.</p>
        </div>
        {categoryName ? categoryName.map((cat: any, index: any) => {
          props.cat = cat
          if (data) {
            Object.keys(data).map((key, i) => {
              if (key === props.cat) {
                props.data = data[key]

              }
            });
          }

          return (
            <Nature {...props} />
          )
        }) : null}
      </div>

      <Footer />
    </>
  )
}

export default CarouselComponent;