// src/redux/profileSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to make the API call
export const editProfile:any = createAsyncThunk("profile/editProfile", async (formData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/user/update`, formData);
    return response.data;
  } catch (error:any) {
    throw error.response.data;
  }
});

const initialState = {
  loading: false,
  error: null,
  profile: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(editProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(editProfile.rejected, (state:any, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default profileSlice.reducer;
