import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import Nature from '../Components/Nature';
import { fetchRequest } from "../Api/fetchRequest";
import { getLocalStorage } from "../utils/utils";
import { useNavigate } from "react-router";
import Header from "./Header";
import { RingLoader, PulseLoader, BarLoader } from "react-spinners";
import { ClipLoader } from "react-spinners";

const override: any = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)"
};

function Favourites() {
  var categoryName: any = '';
  let favouriteArray: { cat: string, data: [] | any } = { cat: "Favourite", data: [] };

  const [data, setData]: any = useState();
  const locationName: any = window.location.href.lastIndexOf("/");
  const navigate = useNavigate()
  const [isEmpty, setIsempty]: any = useState(false);
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    let resp = await fetchRequest('POST', 'page/favorite', undefined);
    loading && setLoading(false);
    !!resp && setData(resp);
    if(resp?.posts?.length < 1){
      setIsempty(true);
    }
  };
  const LoginAccess = getLocalStorage('accessToken')


  useEffect(() => {
    if (LoginAccess) {
      getData();
    }
  }, []);

  if (data) {
    Object.keys(data).map((key, i) => {
      categoryName = Object.keys(data);
    });
  }

  const props = {
    "cat": "",
    "data": []
  }

  const token = getLocalStorage('accessToken')
  const guestToken = getLocalStorage('GUEST_LOGIN')
  useEffect(() => {
    if (!token) {
      navigate('/')
    }

  }, [token, guestToken])

  return (
    <>
      <Header />
      <div className="mt-[180px] md:mt-[120px] mb-[60px]">
        {categoryName ? categoryName.map((cat: any, index: any) => {
          props.cat = cat
          if (data) {
            Object.keys(data).map((key: any, i) => {
              if (key === props.cat) {
                props.data = data[key]
                //  props.data.filter((item: any) => item.favorite).forEach((item) => {
                //    favouriteArray.data.push(item);
                //  })
                favouriteArray.data = [...data?.posts]
              }
            });
          }


          return (
            <></>
          )
        }) : null}
        {isEmpty ? <h2 className="text-4xl text-center">No favorites added yet</h2> : null}
        {

          favouriteArray.data.length && !loading ?

            <Nature {...favouriteArray} />
            :
            <ClipLoader
              // color={color}
              loading={loading}
              cssOverride={override}
              size={110}
              aria-label="Loading Spinner"
              data-testid="loader"
            />


        }
      </div>
    </>
  )
}

export default Favourites;