import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import un from '../../src/Vipasaana.png'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSearchResults } from '../feature/Authentications/searchSlice';
import logo from '../../src/Vipasaana.png'
import { clearLocalStorage, getLocalStorage } from '../utils/utils';
import { isUserlogin } from '../feature/Authentications/GuestLogin-SLice';
import { FaGlobe } from "react-icons/fa";
import user from '../../src/user.png'
import headLogo from "../images/vipassana_high.jpg"
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

const Header = () => {
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    const avatar: any = localStorage.getItem('avatar');
    const [searchTerm, setSearchTerm] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const results = useSelector((state: any) => state.search.data.posts);
    const userLogin = getLocalStorage("accessToken")
    const dropdownRef: any = useRef(null);
    const searchRef: any = useRef(null);
    const [issearched, setissearched]: any = useState(false)
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleChange = (e: any) => {
        setSearchTerm(e.target.value);
        if (!e.target.value) {
            return
        }
        else {
            dispatch(fetchSearchResults(e.target.value));
            setissearched(true)
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event: any) => {
            if (dropdownRef?.current && !dropdownRef?.current?.contains(event.target)) {
                setIsDropdownOpen(false);
            }
            if (searchRef?.current && !searchRef?.current?.contains(event.target)) {
                setissearched(false)
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const logoutHandler = () => {
        clearLocalStorage()
        dispatch(isUserlogin())
        navigate('/')
    }

    // Function will execute on click of button
    const onButtonClick = (fileName: any) => {
        // using Java Script method to get PDF file
        fetch(fileName).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = fileName;
                alink.click();
            })
        })
    }

    const selecteditem = (id: any) => {
        const data = results.find((item: any) => item._id === id);
        // const tracks: any = []
        const obj = {
            id: data._id,
            title: data.name,
            author: data.categoryName,
            audio: data.audio,
            cover: data.cover,
            duration: data.duration,
            description: data.description,
            authorName: data.authorName,
            favorite: data.favorite
        }

        navigate('/details', { state: { track: obj } })
        setSearchTerm('')
    }
    return (
        <>
            <header className='w-full header-wrapper fixed top-0 left-0 z-50'>
                <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800 shadow-lg w-full mx-auto">
                    <div className="flex justify-between items-center mx-auto header-content">
                        <Link to="/home" className="flex items-center logo">
                            <img className="mr-3 h-6 sm:h-9" alt="Logo" src={logo} />
                            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text">Vipassana</span>
                        </Link>
                        <div className='w-[50%] text-center relative search-wrapper' ref={searchRef}>
                            <input
                                type="text"
                                className="w-full max-w-[867px] h-[40px] px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={handleChange}
                            />
                            {searchTerm && issearched && results?.length > 0 && (
                                <ul className="absolute  w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg  z-50">
                                    {results.map((result: any) => (
                                        <li
                                            onClick={() => selecteditem(result._id)}
                                            key={result.id}
                                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                        >
                                            {result.name}
                                        </li>
                                    ))}
                                </ul>
                            )}

                        </div>
                        <Tooltip id="my-tooltip" />
                        <div className="flex my-profile" ref={dropdownRef}>
                            <a href='https://vipassanahawaii.org/' data-tooltip-id="my-tooltip" data-tooltip-content="Vipassana Hawai'i" target='_blank' className=' mt-1'>
                                {/* <FaGlobe size={30} className="text-gray-600" /> */}
                                <img src={headLogo} className="max-w-[35px]" alt="Logo" />
                            </a>
                            <button onClick={toggleDropdown} className="mt-[8px] cursor-pointer ml-4">{userLogin ? (firstName ? '' : 'Profile') : 'Guest'}</button>
                            <div className="flex">
                                <button className="mt-[8px]" onClick={toggleDropdown}>{userLogin ? `${firstName ? firstName : ''}` : ''}</button>
                                <div className='relative'>
                                    <div className="w-[42px] h-[42px] rounded-full overflow-hidden ml-1">
                                        <img
                                            src={avatar === null || avatar === "null" ? user : process.env.REACT_APP_UPLOAD_URL + avatar}
                                            alt="Profile"
                                            className="w-full h-full object-cover cursor-pointer"
                                            onClick={toggleDropdown}
                                        />
                                    </div>
                                    {isDropdownOpen &&
                                        <ul className="absolute whitespace-nowrap right-0 mt-2 bg-white border border-gray-300 rounded-md shadow-lg z-50">
                                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                <Link to="/edit-profile">Edit Profile</Link>
                                            </li>
                                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                <Link to="/favourites">My favorites</Link>
                                            </li>
                                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                <a onClick={() => onButtonClick('PrivacyPolicy.pdf')}>Privacy Policy</a>
                                            </li>
                                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                <Link to="https://vipassanahawaii.org/support/" target='_blank'>Support</Link>
                                            </li>
                                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                <a onClick={() => onButtonClick('TermsOfService.pdf')}>License agreement</a>
                                            </li>
                                            {userLogin &&

                                                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                    <Link to="/" onClick={() => logoutHandler()}>Logout</Link>
                                                </li>
                                            }
                                        </ul>
                                    }
                                </div>
                            </div>

                            {/* <div className="flex items-center gap-3 lg:order-2">

                            <button data-collapse-toggle="mobile-menu-2" type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                                <span className="sr-only">Open main menu</span>
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                                <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </button>
                        </div> */}
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Header