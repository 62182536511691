import { useSelector } from 'react-redux';
import { getLocalStorage } from '../utils/utils';

export const fetchRequest = async (
  method,
  query,
  body,
  withAuth = false,
  defaultToken = false,
) => {
  let token = '';

  try {
    if (withAuth) {
      token = getLocalStorage('GUEST_LOGIN');
      // console.log("token123",token);
    }
     
      if(getLocalStorage('accessToken')) {
        token = getLocalStorage('accessToken');
        // console.log("token456",token);
      }

    // if (defaultToken) {
    //   token = defaultToken;
    // }
    // const resp = await fetchRequest(
    //   'POST',
    //   'user/favorite',
    //   {ID: "params.item._id"},
    //   true,
    // );
    // console.log('API', `${API}/${query}`);
    // console.log('token', token);
    // console.log('body', body);
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${query}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    // console.log('response', response);
    const data = await response.json();
    if(data?.message){
      return data;
    }
    if (response.status === 200 || response.status === 201) {
      // const data = await response.json();
      return data;
    } else if (response.status === 400) {
      // const data = await response.json();
      return data;
    } else if (response.status === 422) {
      // const data = await response.json();
      return data;
    } else if (response.status === 404) {
      // const data = await response.json();
      return data;
    } else if (response.status === 401) {
    } else if (response.status === 403) {
      return false;
    } else if (response.status === 500) {
      alert('Sorry the server is not available right now please try later');
    }
  } catch (error) {
    console.log("new Error==> ", JSON.stringify(error));
  }
};
