import { Route, Routes } from "react-router"
import ForgotPasswordPage from "../pages/AuthPages/ForgotPasswordPage"
import LoginPage from "../pages/AuthPages/LoginPage"
import RegisterPage from "../pages/AuthPages/RegisterPage"
import ResetPasswordPage from "../pages/AuthPages/ResetPasswordPage"
import Recording from "../pages/Recording"
import NotFoundPage from "../pages/NotFoundPage"
import Layout from "./layout/layout"
import ProtectedRoutes from "./ProtectedRoutes"
import CarouselComponent from "../Components/Carousel"
import EditProfile from "../Components/EditProfile"
import Favourites from "../Components/Favourites"
import Subcategory from "../Components/Subcategory"

const Template = () => {
    return (
        <Routes>
            {/* <Route path="/" element={<ProtectedRoutes />}> */}
            <Route path="/" element={<ProtectedRoutes />}>
                <Route path="/" element={<Layout />}>
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
            </Route>
            <Route path="/" >
                <Route path="/" index element={<LoginPage />} />
                <Route path="/login" index element={<LoginPage />} />
                <Route path="/home" index element={<CarouselComponent />} />
                <Route path="/favourites" index element={<Favourites />} />
                <Route path="/subcategory" index element={<Subcategory />} />
                <Route path="/register" index element={<RegisterPage />} />
                <Route path="/details" index element={<Recording />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
                <Route path="/edit-profile" element={<EditProfile />} />
                <Route path="*" element={<NotFoundPage />} />
            </Route>
        </Routes>
    )
}

export default Template