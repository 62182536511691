// @ts-ignore
import AppleSignin from 'react-apple-signin-auth';

/** Apple Signin button */
const MyAppleSigninButton = ({ ...rest }) => (
  <AppleSignin
    /** Auth options passed to AppleID.auth.init() */

    // clientID: com.vipsannaid.app
    // privateKey: QZU8FZR3KV.com.vipsannaid.app
    // Key ID:73293QL9SC
    // Team ID: QZU8FZR3KV

    authOptions={{
      clientId: "com.vipsannaid.app.client",
      scope: "email name",
      redirectURI: "https://app.vipassanahawaii.org/",
      state: "origin:web",
      nonce: "nonce",
      usePopup: true
    }}
    /** General props */
    // uiType="dark"
    // /** className */
    // className="apple-auth-btn"
    // /** Allows to change the button's children, eg: for changing the button text */
    // buttonExtraChildren="continue with Apple"
    // //onSuccess response object will contain the user object on the first time attempt only

    // /** Spread rest props if needed */
    {...rest}
  />
);

export default MyAppleSigninButton;
