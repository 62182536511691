import React from "react";

const PlayList = ({ track, selectedTrack, setSelectedTrack }: any) => {

  const description = (description: any) => {
    if (description) {
      if (description.length > 45) {
        return description.slice(0, 45) + '...';
      } else return description;
    } else return '';
  };


  return (
    <div className="playlist">
      <div
        key={track.id}
        className={
          track.id === selectedTrack?.id
            ? "playlist-item selected item-grid-card__title"
            : "playlist-item item-grid-card__title"
        }
        onClick={() => setSelectedTrack(track)}
      >
        <p className="item-grid-card__title">{track.title}</p>
        <p className="item-grid-music-preview__author">{track?.authorName ? 'by ' + track?.authorName : ''}</p>
        <p className="item-grid-music-preview__author">{description(track?.description)}</p>
      </div>
      {/* <div className="flex text-xs gap-2 pt-2">
            <a className="inline-block rounded-md border px-[5px] py-[7px]" href="#">Corporate</a>
            <a className="inline-block rounded-md border px-[5px] py-[7px]" href="#">Happy</a>
            <a className="inline-block rounded-md border px-[5px] py-[7px]" href="#">Uplifting</a>
            <a className="inline-block rounded-md border px-[5px] py-[7px]" href="#">Intense</a>
            <a className="inline-block rounded-md border px-[5px] py-[7px]" href="#">...</a>
          </div> */}
    </div>
  );
};

export default PlayList;
