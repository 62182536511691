import { BrowserRouter } from 'react-router-dom';

import Templete from './template/template'

function App() {
  return (
    <>
     <BrowserRouter>
      <Templete />
     </BrowserRouter>
    </>
  );
}

export default App;
