import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { Guestlogin } from '../feature/Authentications/GuestLogin-SLice';
import logo from '../Vipasaana.png'

const BeforeLoginHeader = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const guestLogin = () => {
        dispatch(Guestlogin()).then((val: any) => {
            if (val?.meta?.requestStatus == "fulfilled") {
                navigate('/home')
            }
        }
        )

    }
    return (
        <>
            <header className='w-full fixed top-0 left-0'>
                <nav className="bg-white border-gray-200 px-2 lg:px-6 py-2.5 dark:bg-gray-800 shadow-lg w-full max-w-[1920px] mx-auto">
                    <div className="flex gap-2 flex-wrap justify-between items-center mx-auto">
                        <a href="" className="flex items-center">
                            <img src={logo} className="mr-3 h-6 sm:h-9" alt="Logo" />
                            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text">Vipassana</span>
                        </a>
                        <div className="flex items-center gap-2 lg:gap-3 lg:order-3">
                            <Link className="text-green rounded-xl px-4 py-2  border border-green" to='/login' >Login</Link>
                            <Link className="text-white rounded-xl px-4 py-2  bg-green" to='/register'>Register</Link>
                            <div className="text-black  md:px-4 md:py-2 cursor-pointer" onClick={() => guestLogin()}>
                                Continue as Guest ?
                            </div>

                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default BeforeLoginHeader