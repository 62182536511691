import React, { useRef } from "react";
import forward from '../forward.png'
import backword from '../backword.png'

function AudioPlayer(props: any) {

  const audioRef: any = useRef(null);
  const propData = props.track;
  const handleForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 15;
    }
  };

  const handleBackward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 15;
    }
  };

  return (
    <div className="flex mb-4 justify-center audio-wrapper">
      <button onClick={handleBackward}><img src={forward} alt="Center Image" className=" h-8 mb-0 mr-[15px] previous" /></button>
      <audio ref={audioRef} src={propData} controls className="w-[420px]  current" />
      <button onClick={handleForward}><img src={backword} alt="Center Image" className="h-8 mb-0 ml-[15px] forward" /></button>
    </div>

  );
}

export default AudioPlayer;