import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Router } from "react-router";
import { apiEndpoint } from "../../config/apiEndpoint";
import { http } from "../../config/http";
import {
    setLocalStorage,
    showToastErrorMessage,
    showToastSuccessMessage,
} from "../../utils/utils";

interface ILogin {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    e: {
        mobile: string;
        sms: number;
    };
    userDetails:{}
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    mobile: "",
    sms: "",
    userDetails :{}
};

export const loginMobile: any = createAsyncThunk(
    "loginMobileSlice",
    async ({ e }: ILogin) => {
        try {
            const response = await http.post(
                `${process.env.REACT_APP_BASE_URL}${apiEndpoint.authentication.mobile}`,
                {
                    phone: `${'+'}${e?.mobile}`,
                    code: e?.sms,
                }
            );
            // console.log("RES@",response);
            

            if (response?.data?.success == true) {    
                if (response?.data?.valid == false) {
                    // showToastErrorMessage("Invalid SMS")
                }
                if (response?.data?.valid == true && !response?.data?.token) {
                    // showToastErrorMessage("Try Again")
                }
                // if (response?.data?.valid == true && response?.data?.token) {
                  
                    
                // }
                if (response?.data?.success == true && response?.data?.valid == true && response?.data?.token) {
                    const accessToken = response?.data?.token;
                    setLocalStorage("accessToken", accessToken);
                    setLocalStorage("firstName", response?.data?.user?.firstName);
                     setLocalStorage("lastName", response?.data?.user?.lastName);
                     setLocalStorage("email", response?.data?.user?.email);
                     setLocalStorage("avatar", response?.data?.user?.avatar);
                    showToastSuccessMessage("Login Successfully");
                }
                return response;
            } 
        } catch (error: any) {
            // showToastErrorMessage('Invalid SMS.');
            throw error; // Re-throw the error to be caught by rejected case
        }
    }
);

export const loginMobileSlice = createSlice({
    name: "loginMobileSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loginMobile.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(loginMobile.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.userDetails = payload?.data?.user
        });
        builder.addCase(loginMobile.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const loginMobileSliceReducer = loginMobileSlice.reducer;
