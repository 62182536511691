import Tracks from './Tracks';
import { useNavigate } from 'react-router';
import Notracks from './Notracks';
import Advertise from '../../src/images/VH.jpg'

function Nature(props: any) {

    const navigate = useNavigate()
    const hasAudio = props?.data?.some((e: any) => e.hasOwnProperty('audio'));
    var className = "";
    var tagcloud = "";
    if (props.data.length !== 0 || props.data == undefined) {
        className = "w-[90%] md:w-[75%] mx-auto mt-[60px]  block";
    }
    else {
        className = "hidden";
    }

    if (hasAudio) {
        tagcloud = "hidden";
    }
    else {
        tagcloud = "tagcloud";
    }

    const navigation = () => {
        navigate('/subcategory')
    }

    const capitalizeFirst = (str: any) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    // console.info('data', props?.cat);
    // gmeditations
    return (
        <div className={className}>
            {/* {(props?.cat === 'chanting') && (
                <div className="flex gap-4">
                    <img
                        src={Advertise}
                        alt=""
                        className="w-full h-full object-cover"
                    />
                </div>
            )} */}


            {(props?.data[0]?.mainCateg) ? (
                <div className="flex gap-4">
                    <h4 className="text-xl font-medium">{capitalizeFirst(props?.data[0]?.mainCateg)}</h4><hr />
                </div>
            ) : (
                <div className="flex gap-4">
                    <h4 className="text-xl font-medium">{capitalizeFirst(props.cat)}</h4><hr />
                </div>
            )}

            {/* <div className={tagcloud}>
                <button >All</button>
                {props.data.map((data: any, index: any) => {
                    return (
                        <>
                            <button>{data.name}</button>
                        </>
                    )
                })}
            </div> */}
            {hasAudio ? <Tracks {...props} /> : <Notracks {...props} />}
        </div>
    );
}
export default Nature;