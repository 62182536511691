import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiEndpoint } from "../../config/apiEndpoint";
import { http } from "../../config/http";
import {
  setLocalStorage,
  showToastErrorMessage,
  showToastSuccessMessage,
} from "../../utils/utils";

interface IRegister {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean;
  data: {
    password: string;
    email: string;
  };
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  email: "",
  password: "",
};

export const registerApi: any = createAsyncThunk(
  "registerSlice",
  async ({ data }: IRegister) => {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASE_URL}${apiEndpoint.authentication.register}`,
        {
          email: data?.email,
          password: data.password,
        }
      );
      
      if (response?.data?.success == true) {
        const accessToken = response?.data?.token;
        setLocalStorage("accessToken", accessToken);
        setLocalStorage("email", response?.data?.user?.email);
        showToastSuccessMessage("Register Successfully");
        return response;
      } else {
        throw new Error("Invalid response status");
      }
    } catch (error: any) {      
      showToastErrorMessage(error?.response.data.message);
      throw error; // Re-throw the error to be caught by rejected case
    }
  }
);

export const registerSlice = createSlice({
  name: "registerSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerApi.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(registerApi.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.email = payload.email;
      state.password = payload.password;
    });
    builder.addCase(registerApi.rejected, (state) => {
      state.isError = true;
    });
  },
});

export const registerSliceReducer = registerSlice.reducer;
