import { toast } from "react-toastify"

export const setLocalStorage = (key:string, value:string) => {
	localStorage.setItem(key, value)
}

export const getLocalStorage = (key:string) => {
	return localStorage.getItem(key)
}

export const removeLocalStorage = (key:string) => {
	localStorage.removeItem(key)
}

export const clearLocalStorage = () => {
	localStorage.clear()
}

export const showToastSuccessMessage = (message: any) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  
  export const showToastErrorMessage = (message: any) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
